@font-face {
    font-family: "AmazonEmber";
    src: local("AmazonEmber"),
        url(./assets/fonts/Amazon_Ember_Rg.ttf) format("truetype");
}

@font-face {
    font-family: "AmazonEmber-Bold";
    src: local("AmazonEmber-Bold"),
        url(./assets/fonts/Amazon_Ember_Bd.ttf) format("truetype");
}

@font-face {
    font-family: "AmazonEmberMono-Bold";
    src: local("AmazonEmberMono-Bold"),
        url(./assets/fonts/AmazonEmberMono_Bd.ttf) format("truetype");
}

@font-face {
    font-family: "AmazonEmberDisplayCdApp-Heavy";
    src: local("AmazonEmberDisplayCdApp-Heavy"),
        url(./assets/fonts/AmazonEmberDisplayCd.ttf) format("truetype");
}

@font-face {
    font-family: "SharpGroteskBlack-18";
    src: local("SharpGroteskBlack-18"),
        url(./assets/fonts/SharpGroteskPanEuroBlack-18.ttf) format("truetype");
}

@font-face {
    font-family: "SharpGroteskBlackItl-18";
    src: local("SharpGroteskBlackItl-18"),
        url(./assets/fonts/SharpGroteskPanEuroBlackItl-18.ttf) format("truetype");
}

@font-face {
    font-family: "SharpGroteskBold-16";
    src: local("SharpGroteskBold-16"),
        url(./assets/fonts/SharpGroteskPanEuroBold-16.ttf) format("truetype");
}

@font-face {
    font-family: "SharpGroteskBold-20";
    src: local("SharpGroteskBold-20"),
        url(./assets/fonts/SharpGroteskPanEuroBold-20.ttf) format("truetype");
}

@font-face {
    font-family: "SharpGroteskBoldItl-16";
    src: local("SharpGroteskBoldItl-16"),
        url(./assets/fonts/SharpGroteskPanEuroBoldItl-16.ttf) format("truetype");
}

@font-face {
    font-family: "SharpGroteskBoldItl-20";
    src: local("SharpGroteskBoldItl-20"),
        url(./assets/fonts/SharpGroteskPanEuroBoldItl-20.ttf) format("truetype");
}

@font-face {
    font-family: "SharpGroteskBook-16";
    src: local("SharpGroteskBook-16"),
        url(./assets/fonts/SharpGroteskPanEuroBook-16.ttf) format("truetype");
}

@font-face {
    font-family: "SharpGroteskBookItl-16";
    src: local("SharpGroteskBookItl-16"),
        url(./assets/fonts/SharpGroteskPanEuroBookItl-16.ttf) format("truetype");
}

@font-face {
    font-family: "SharpGroteskMedium-16";
    src: local("SharpGroteskMedium-16"),
        url(./assets/fonts/SharpGroteskPanEuroMedium-16.ttf) format("truetype");
}

@font-face {
    font-family: "SharpGroteskMediumItl-16";
    src: local("SharpGroteskMediumItl-16"),
        url(./assets/fonts/SharpGroteskPanEuroMediumItl-16.ttf) format("truetype");
}

@font-face {
    font-family: "SharpGroteskSmBold-20";
    src: local("SharpGroteskSmBold-20"),
        url(./assets/fonts/SharpGroteskPanEuroSmBld-20.ttf) format("truetype");
}

@font-face {
    font-family: "SharpGroteskSmBoldItl-20";
    src: local("SharpGroteskSmBoldItl-20"),
        url(./assets/fonts/SharpGroteskPanEuroSmBldItl-20.ttf) format("truetype");
}