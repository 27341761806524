#scroll::-webkit-scrollbar {
	width: 8px;
}

 #scroll::-webkit-scrollbar-track {
    background: rgba(0,0,0,0.6);
} 

#scroll::-webkit-scrollbar-thumb {
    background-color: rgb(33,33,33);
    border-radius: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}