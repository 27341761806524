body {
    background-color: rgb(20, 25, 26);
}

#root {
    overflow: hidden;
}

.rootContainer {
    max-width: 1200px;
    margin: auto !important;
}

.topOverlayPresent .rootContainer.overlaid {
    padding-top: 80px;
}

/* Style for datepicker current date marker */
button.rdrDayToday span.rdrDayNumber span::after {
    background-color: #25d1da;
}

img {
    object-fit: contain;
}

.row {
    margin-left: 0px;
    margin-right: 0px;
}

* {
    outline: none;
}

/* Dropdown Styles */

#timepicker::after,
#timepicker.show::after,
#needhelpdropdown::after,
#needhelpdropdown.show::after {
    display: none;
}

@media screen and (max-width: 600px) { /* matches breakpoints */
    .ProfileHeadImage {
        width: 96px !important;
        height: 96px !important;
    }
}

.dropdown-toggle::after {
    top: 20px;
    position: absolute;
    right: 15px;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-bottom: 0;
    border-left: 0.4em solid transparent;
}

.show .dropdown-toggle::after {
    top: 20px;
    position: absolute;
    right: 15px;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-bottom: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-top: 0;
    border-left: 0.4em solid transparent;
}

.show .avatarDropdown,
.avatarDropdown {
    height: 48px;
    width: 48px;
    border-radius: 24px;
    padding: 0px;
    overflow: hidden;
}

.show .avatarDropdown::after,
.avatarDropdown::after {
    display: none;
}

.show .manageMemberDropdown,
.manageMemberDropdown {
    padding: 0px;
    overflow: hidden;
}

.show .manageMemberDropdown::after,
.manageMemberDropdown::after {
    display: none;
}

.show .trackAlbumMoreDropdown,
.trackAlbumMoreDropdown,
.dropright .dropdown-toggle::after {
    padding: 0px;
    overflow: hidden;
}

.show .trackAlbumMoreDropdown::after,
.trackAlbumMoreDropdown::after,
.dropright .dropdown-toggle::after {
    display: none;
}

.dropdown-menu {
    padding: 20px;
    margin-top: 25px;
    background: rgba(0, 0, 0, 0.8);
    box-shadow: 0 10px 34px 0 rgba(0, 0, 0, 0.5);
    border-radius: 16px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
}

.tooltip {
    margin-bottom: 3px;
    opacity: 1;
}

.tooltip-inner {
    max-width: 320px;
    padding: 15px;
    background: rgba(0, 0, 0, 0.9);
    box-shadow: 0 10px 34px 0 rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
}

/* 
.avatarDropdown:after,
.rightPointer .dropdown-menu:after {
    content: '';
    position: absolute;
    top: 0;
    left: 80%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.50);
    border-top: 0;
    margin-top: -20px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.leftPointer .dropdown-menu:after,
.centerPointer .dropdown-menu:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.50);
    border-top: 0;
    margin-top: -20px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
} */

.dropdown-menu .dropdown-item {
    min-height: 64px;
    padding-top: 20px;
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    background: transparent;
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:active {
    height: 64px;
    vertical-align: center;
    color: rgba(255, 255, 255, 1);
    opacity: 0.7;
    background: transparent;
}

.dropdown-menu .dropdown-item:last-child {
    border-bottom: none;
}

/* Navigation */

.nav-item a:hover {
    text-decoration: none;
    color: #25d1da !important;
}

.nav-item a:focus {
    text-decoration: none;
    color: #25d1da !important;
}

.nav-item a:active {
    text-decoration: none;
    color: #25d1da !important;
}

.nav-item a:disabled {
    text-decoration: none;
    opacity: 0.7;
}

.bm-burger-button {
    background-color: #333 !important;
}

/* Truncated text */
.truncatedOneLineText {
    text-overflow: ellipsis;
    hyphens: auto;
    overflow: hidden;
    overflow-wrap: anywhere;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.truncatedTwoLinesText {
    text-overflow: ellipsis;
    hyphens: auto;
    overflow: hidden;
    overflow-wrap: anywhere;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.modal {
    display: flex !important;
    align-items: center;
}

.modal-dialog {
    display: flex;
    margin: "auto";
}

/* React Switch Animations */
.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100%);
    transform: translateX(100%);
}

/* For ImageWithGradient to add gradient overlay onto image */
.image-gradient-filter {
    position:relative;
    display:inline-block;
}

.image-gradient-filter:after {
    position: absolute;
    content:"";
    height:100%;
    width:100%;
    top:0;
    left:0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgb(0, 0, 0, 1));
}
