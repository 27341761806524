p.p1 {
    margin: 0px 0px 12px 0px;
    text-align: justify;
    line-height: 22px;
    font: 18.7px "AmazonEmber";
    color: #ffffff;
    -webkit-text-stroke: #ffffff;
}
p.p2 {
    margin: 0px 0px 12px 0px;
    text-align: justify;
    line-height: 17px;
    font: 14.7px "AmazonEmber";
    color: #ffffff;
    -webkit-text-stroke: #ffffff;
}
p.p3 {
    margin: 0px 0px 12px 24px;
    text-align: justify;
    text-indent: -24px;
    line-height: 17px;
    font: 14.7px "AmazonEmber";
    color: #ffffff;
    -webkit-text-stroke: #ffffff;
}
p.p4 {
    margin: 0px 0px 12px 24px;
    text-align: justify;
    line-height: 17px;
    font: 14.7px "AmazonEmber";
    color: #ffffff;
    -webkit-text-stroke: #ffffff;
}
p.p5 {
    margin: 0px 0px 12px 0px;
    line-height: 17px;
    font: 14.7px "AmazonEmber";
    color: #ffffff;
    -webkit-text-stroke: #ffffff;
}
p.p6 {
    margin: 0px 0px 12px 52.8px;
    text-align: justify;
    text-indent: -28.8px;
    line-height: 17px;
    font: 14.7px "AmazonEmber";
    color: #ffffff;
    -webkit-text-stroke: #ffffff;
}
p.p7 {
    margin: 0px 0px 12px 52.8px;
    text-align: justify;
    line-height: 17px;
    font: 14.7px "AmazonEmber";
    color: #ffffff;
    -webkit-text-stroke: #ffffff;
}
p.p8 {
    margin: 0px 0px 12px 52.8px;
    text-align: justify;
    line-height: 17px;
    font: 14.7px "AmazonEmber";
    color: #ffffff;
    -webkit-text-stroke: #ffffff;
}
p.p9 {
    margin: 0px 0px 12px 24px;
    text-align: justify;
    line-height: 17px;
    font: 14.7px "AmazonEmber";
    color: #ffffff;
    -webkit-text-stroke: #ffffff;
}
p.p10 {
    margin: 0px 0px 12px 0px;
    line-height: 17px;
    font: 14.7px "AmazonEmber";
    color: #ffffff;
    -webkit-text-stroke: #ffffff;
}
p.p11 {
    margin: 0px 0px 12px 24px;
    text-align: justify;
    text-indent: -12px;
    line-height: 17px;
    font: 14.7px "AmazonEmber";
    color: #ffffff;
    -webkit-text-stroke: #ffffff;
}
span.s1 {
    font-kerning: none;
}
span.s2 {
    font: 14.7px "AmazonEmber";
    font-kerning: none;
}
span.s3 {
    font: 14.7px "AmazonEmber";
    text-decoration: underline;
    font-kerning: none;
    color: #25d1da;
    -webkit-text-stroke: 0px #25d1da;
}
span.s4 {
    font: 9.3px "AmazonEmber";
    font-kerning: none;
}
span.s5 {
    font: 14.7px "AmazonEmber";
    font-kerning: none;
}
span.s6 {
    font: 10.7px "AmazonEmber";
    text-decoration: underline;
    font-kerning: none;
    color: #25d1da;
    -webkit-text-stroke: 0px #25d1da;
}
span.s7 {
    font: 10.7px "AmazonEmber";
    font-kerning: none;
}
span.s8 {
    font: 14.7px "AmazonEmber";
    text-decoration: underline;
    font-kerning: none;
    color: #25d1da;
    -webkit-text-stroke: 0px #25d1da;
}
