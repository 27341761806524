.modal {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    position: fixed;
    z-index: 99999;
}

@supports not (backdrop-filter: none) {
    .modal {
        background: rgba(0, 0, 0, 1);
    }
}
@supports (backdrop-filter: none) {
    .modal {
        background: rgba(0, 0, 0, 0.8);
    }
}

.modal-dialog,
.modal-content,
.modal-body {
    background-color: transparent !important;
    width: 100%;
    height: auto;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    box-shadow: none;
    -webkit-box-shadow: none;
}

.modal-backdrop {
    background: transparent;
}
