.modal-content {
    margin: auto;
    width: 400px;
    padding: 20px;
    margin-top: 25px;
    box-shadow: 0 10px 34px 0 rgba(0, 0, 0, 0.5);
    border-radius: 16px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
}

@supports not (backdrop-filter: none) {
    .modal-content {
        background: rgba(0, 0, 0, 1);
    }
}
@supports (backdrop-filter: none) {
    .modal-content {
        background: rgba(0, 0, 0, 0.8);
    }
}

.modal-body .rdrDateDisplayWrapper,
.modal-body .rdrMonthAndYearWrapper,
.modal-body .rdrCalendarWrapper,
.modal-body .rdrDateRangeWrapper {
    background: transparent;
}

.modal-body .rdrDateDisplayItem,
.modal-body .rdrDateDisplayItemActive {
    font-family: "AmazonEmber-Bold";
    font-weight: 400;
    background: transparent;
    color: white;
}

.rdrDayPassive,
.rdrDayDisabled {
    opacity: 0.6;
    background-color: transparent;
}

.rdrDateDisplayItem input {
    text-transform: uppercase;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 12px;
}

.rdrDateDisplayItemActive {
    background-color: rgba(255, 255, 255, 0.15) !important;
    border-radius: 16px;
    color: #fff;
    outline: none;
}

.rdrDateDisplayItemActive input {
    color: #fff;
}

.rdrDay .rdrDayNumber span {
    color: #fff;
}

.rdrMonth {
    font-family: "AmazonEmber";
    padding-bottom: 0;
}

.rdrMonthAndYearPickers select {
    color: white;
    text-decoration: underline;
    background: url(../../../assets/icons/ic_chevron_caretdown.svg) no-repeat;
    background-color: rgba(0, 0, 0, 0.8) !important; /* firefox */
}

.rdrMonthPicker select {
    font-family: "AmazonEmber";
    text-transform: uppercase;
    background-position: right 16px center;
}

.rdrYearPicker select {
    font-family: "AmazonEmber";
    text-transform: uppercase;
    background-position: right 8px center;
}

.rdrMonthPicker option,
.rdrYearPicker option {
    font-size: 1rem; /* firefox */
}

.modal-footer {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.rdrNextPrevButton:active,
.rdrNextPrevButton:focus,
.rdrNextPrevButton:hover {
    border: 0;
    outline: none;
    background: rgba(255, 255, 255, 0.3);
}

.rdrNextPrevButton {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
}

.rdrNextButton i {
    transform: translate(3px, 0px);
    border-color: transparent transparent transparent rgb(255, 255, 255);
}

.rdrPprevButton i {
    border-color: transparent rgb(255, 255, 255) transparent transparent;
    transform: translate(-3px, 0px);
}
